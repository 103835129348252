import React from 'react';
import { useTranslation } from 'react-i18next';
import Component from '../../components/Reviews.js';

import './Laurels.css';

const Laurels = () => {
  const { t } = useTranslation();
  return (
    <div className="app__bg section__padding1" id="awards">
      <div className="app__wrapper_info">
        <h1 className="headtext__cormorant">{t('google')}</h1>

        <div className="app__laurels_awards">
          <Component />
        </div>
      </div>
    </div>
  );
};

export default Laurels;
