import React from 'react';

import './News.css';
import { Newsletter } from '../../components';

/*   <div className="app__aboutus-overlay flex__center">
<img src={images.G} alt="G_overlay" />
</div> */

const News = () => (
  <div className="app__aboutus app__bg section__padding">
    <Newsletter />
  </div>
);

export default News;
