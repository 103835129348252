import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar app__bg">
      <div className="app__navbar-logo">
        <img src={images.gericht} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__cormorant"><a href="#home">Home</a></li>
        <li className="p__cormorant"><a href="#about">{t('greeting')}</a></li>
        <li className="p__cormorant"><a href="#menu">{t('prices')}</a></li>
        <li className="p__cormorant"><a href="#awards">{t('reviews')}</a></li>
        <li className="p__cormorant"><a href="#contact">{t('contacts')}</a></li>
      </ul>
      <div className="app__navbar-login">
        <a href="#en" className="p__cormorant" onClick={() => i18n.changeLanguage('en')}>Eng</a>
        <div />
        <a href="/" className="p__cormorant" onClick={() => i18n.changeLanguage('it')}>Ita</a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay app__Sfondo flex__center slide-bottom">
            <GiHamburgerMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>{t('greeting')}</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>{t('prices')}</a></li>
              <li><a href="#awards" onClick={() => setToggleMenu(false)}>{t('reviews')}</a></li>
              <li><a href="#contact" onClick={() => setToggleMenu(false)}>{t('contacts')}</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
