import React from 'react';
import { useTranslation } from 'react-i18next';

import { images } from '../../constants';
import './Chef.css';

const Chef = () => {
  const { t } = useTranslation();
  return (
    <div className="app__bg section__padding">
      <div className="app__wrapper_info">
        <h1 className="headtext__cormorant">{t('comfort')}</h1>
        <div className="app__chef-content">
          <div className="app__chef-content_quote">
            <img src={images.quote} alt="quote_image" />
            <p className="p__opensans1"><i>{t('human')}</i></p>
            <img src={images.quota} alt="quota_image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chef;

