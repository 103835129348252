import React from 'react';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { FooterOverlay } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="app__footer" id="login">
      <FooterOverlay />

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">{t('contact')}</h1>
          <p className="p__opensans">Via Luigi Cadorna n.5, 22100 Como</p>
          <p className="p__opensans">cell: +39 371-3501881</p>
          <p className="p__opensans">tel: +39 031-6127602</p>
        </div>

        <div className="app__footer-links_logo">
          <img src={images.gericht} alt="footer_logo" />
          <p className="p__opensans">&quot;<i>Relax your mind, calm your body, renew your spirit</i> &quot;</p>
          <div className="app__footer-links_icons">
            <a href="https://www.facebook.com/people/MaiThai-Massage/100057343423756/" target="_blank" rel="noreferrer">
              <FiFacebook />
            </a>
            <a href="https://www.instagram.com/maithai_lakecomo/?hl=en" target="_blank" rel="noreferrer">
              <FiInstagram />
            </a>
          </div>
        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">{t('working')}</h1>
          <p className="p__opensans">{t('ore')}</p>
          <p className="p__opensans">10:00 - 20:00</p>
        </div>
      </div>

      <div className="footer__copyright">
        <p className="p__opensans">2022 Maithai. All Rights reserved.</p>
      </div>

    </div>
  );
};

export default Footer;
