import bg from '../assets/bg.png';
import gallery04 from '../assets/gallery04.png';
import gallery06 from '../assets/gallery06.JPG';
import gallery08 from '../assets/gallery08.JPG';
import gallery01 from '../assets/gallery01.jpg';
import gallery02 from '../assets/gallery02.jpg';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import quota from '../assets/quota.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bg,
  gallery04,
  gallery02,
  gallery06,
  gallery08,
  quote,
  gericht,
  quota,
  gallery01,
};
