import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { News, AboutUs, Chef, FindUs, Footer, Header, Intro, Laurels, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    <SpecialMenu />
    <Chef />
    <Intro />
    <Laurels />
    <FindUs />
    <News />
    <Footer />
    <CookieConsent style={{ background: '#DCCA87' }} buttonStyle={{ background: '#F5EFDB', font: '--font-base' }}>This website uses cookies to enhance the user experience.</CookieConsent>
  </div>
);

export default App;
