import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="app__bg section__padding" id="about">

      <h1 className="headtext__cormorant"><center>{t('greeting')}</center></h1>

      <div className="app__aboutus-content_about">
        <img src={images.gallery02} alt="4" className="image" />
        <p className="app__aboutus-content_about_text">{t('about_1')} </p>
      </div>
    </div>
  );
};

export default AboutUs;
