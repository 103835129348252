/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

const FindUs = () => {
  const { t } = useTranslation();
  return (
  <div className="app__bg section__padding" id="contact">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">{t('find')} </h1>
        <p className="p__opensans1"><big>{t('via')}</big></p>
        <p className="p__opensans1"><i><small>{t('please')}</small></i></p>
        <p className="p__opensans1"><i><small>{t('our_2')}</small></i></p>
    </div>
    <div className="app__wrapper_img">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.322478392167!2d9.082742015661891!3d45.80479967910638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47869d98d5cb7249%3A0x41763a2a5f392955!2sMaithai%20-%20Massaggi%20Thailandese!5e0!3m2!1sen!2sit!4v1664761783802!5m2!1sen!2sit" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
);
};

export default FindUs;
