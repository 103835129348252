import React from 'react';
import Slider from 'infinite-react-carousel';
import './Header.css';
import Audio from '../../components/Audio';
import { images } from '../../constants';

const Header = () => (
  <div className="app__bg app__wrapper section__padding header__unique" id="home">
    <Audio />
    <Slider
      arrows={false}
      arrowsScroll={1}
      autoplay
      autoplaySpeed={4000}
      duration={500}
      slidesToShow={1}
      wheel={false}
      infinite
    >
      <div>
        <img src={images.gallery06} alt="6" className="app__header-img" />
      </div>
      <div>
        <img src={images.gallery01} alt="1" className="app__header-img" />
      </div>
      <div>
        <img src={images.gallery04} alt="4" className="app__header-img" />
      </div>
      <div>
        <img src={images.gallery08} alt="8" className="app__header-img" />
      </div>
    </Slider>
  </div>
);

export default Header;
