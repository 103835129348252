import React from 'react';
import { useTranslation } from 'react-i18next';

import './Newsletter.css';

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        <h1 className="headtext__cormorant_2">{t('subscribe')}</h1>
        <p className="p__opensans2">{t('updates')}</p>
        <p className="p__opensans2">{t('updates1')}</p>
      </div>
      <div className="app__newsletter-input flex__center">
        <a href="https://drive.google.com/file/d/1fw_EdpPBw94KqQBxASX9EWr5qZnqbe3l/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="button-link">
          {t('privacy')}
        </a>
        <a href="https://drive.google.com/file/d/1jMnA-bf2aTOV1v549I18D2Ld1I7mh4OW/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="button-link">
          {t('module')}
        </a>
      </div>
    </div>
  );
};

export default Newsletter;
