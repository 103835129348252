import React from 'react';

import { meal } from '../../constants';
import './Intro.css';

const Intro = () => {
  const [playVideo, setPlayVideo] = React.useState(false);
  const vidRef = React.useRef();

  return (
    <div
      className="app__video"
      onClick={() => {
        setPlayVideo(!playVideo);
        if (playVideo) {
          vidRef.current.pause();
        } else {
          vidRef.current.play();
        }
      }}
    >
      <video
        ref={vidRef}
        src={meal}
        type="video/mp4"
        loop
        controls={false}
        muted
        poster="intro.png"
      />
    </div>
  );
};

export default Intro;
