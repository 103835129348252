import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubHeading } from '../../components';
import './SpecialMenu.css';

const SpecialMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="app__specialMenu app__bg flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title={t('relaxation')} />
        <h1 className="headtext__cormorant">{t('our_1')}</h1>
      </div>

      <div className="app__specialMenu-menu1">
        <div className="app__specialMenu-item_heading">{t('oil')}</div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description">
          <p className="app__specialMenu-item_text">{t('oil_text')}</p>
        </div>
        <div className="app__specialMenu-item_price">
          <p>60 min / € 60</p>
          <p>90 min / € 85</p>
          <p>120 min / € 120</p>
        </div>
      </div>

      <div className="app__specialMenu-menu1">
        <div className="app__specialMenu-item_heading">{t('maithai')}</div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description">
          <p className="app__specialMenu-item_text">{t('maithai_text')}</p>
        </div>
        <div className="app__specialMenu-item_price">
          <p>60 min / € 60</p>
          <p>90 min / € 85</p>
          <p>120 min / € 120</p>
        </div>
      </div>
      <div className="app__specialMenu-menu1">
        <div className="app__specialMenu-item_heading">{t('couple')}</div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description">
          <p className="app__specialMenu-item_text">{t('couple_text')}</p>
        </div>
        <div className="app__specialMenu-item_price">
          <p>60 min / € 120</p>
          <p>90 min / € 170</p>
          <p>120 min / € 240</p>
        </div>
      </div>
      <div className="app__specialMenu-menu1">
        <div className="app__specialMenu-item_heading">{t('traditional')}</div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description">
          <p className="app__specialMenu-item_text">{t('traditional_text')}</p>
        </div>
        <div className="app__specialMenu-item_price">
          <p>60 min / € 60</p>
          <p>90 min / € 85</p>
          <p>120 min / € 120</p>
        </div>
      </div>
      <div className="app__specialMenu-menu1">
        <div className="app__specialMenu-item_heading">{t('foot')}</div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description">
          <p className="app__specialMenu-item_text">{t('foot_text')}</p>
        </div>
        <div className="app__specialMenu-item_price">
          <p>60 min / € 50</p>
          <p>90 min / € 75</p>
          <p>120 min / € 100</p>
        </div>
      </div>

      <a href="https://wa.me/+393713501881">
        <button type="button" className="custom__button"><big>{t('book')}</big></button>
      </a>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-item_description_1">
          <p className="app__specialMenu-item_text1">{t('nb')}</p>
        </div>
      </div>
    </div>
  );
};

export default SpecialMenu;
