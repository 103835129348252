import React, { Component } from 'react';
import { Howl, Howler } from 'howler';
import Music from '../assets/Music.mp3';

// eslint-disable-next-line react/prefer-stateless-function
class Audio extends Component {
    Sound = () => {
      const sound = new Howl({
        src: [Music],
      });
      sound.play();
    }

    render() {
      Howler.volume(1.0);
      return (
        <div>
          {this.Sound()}
        </div>
      );
    }
}

export default Audio;
